import { registerApplication, start } from 'single-spa'
import {
  constructApplications,
  constructRoutes,
  constructLayoutEngine
} from 'single-spa-layout'

const htmlTemplate = document.querySelector('#single-spa-layout')

const resolvedRoutes = constructRoutes(htmlTemplate)

const applications = constructApplications({
  routes: resolvedRoutes,
  loadApp: (app) => System.import(app.name)
})

constructLayoutEngine({
  routes: resolvedRoutes,
  applications: applications,
})

applications.forEach(registerApplication)

start()
